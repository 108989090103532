/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.borde-pred{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}
table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

td, th {
  width: 25%;
}
.center{
  text-align: center;
  display: flex!important;
  flex-wrap: wrap;
  justify-content: center!important;
}
.border-left {
  border-left: 1px solid #7a7a7a!important;
}

.block{
  display: block!important;
}
.flex{
  display: flex!important;
}
.redondo{
  display: inline;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-inline: 1rem;
}
.link{
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.my-custom-class{
  position: inherit !important;

}
ion-content{
  background: blue;     // to treat it as normal css
}
ion-modal#modal-camicu {
  --width: 700px;
  --height: 100%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.popover-content {
  position: inherit !important;
}
.contact-popover .popover-content{ width: 320px; }

ion-popover {
  div.popover-content {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
}
}


ion-popover .popover-content{
  margin-left: auto;
  margin-right: auto;
  --background: white;
}
.popover .popover-content {
  color: red;
  font-size: 10px;
}
.popover_class{
  --background: blue;  // to take care of a possible css4
  background: blue;     // to treat it as normal css

}
$popover-md-width: 320px; $popover-ios-width: 320px; $popover-wp-width: 320px;
.contact-popover .popover-content{ width: 320px; }
ion-popover {
  --width: 820px;
}

.select-alert {
  --min-width: 400px !important; /* Ajusta este valor según necesites */
  --max-width: 600px !important; /* Ajusta este valor según necesites */
}

.item-input.sc-ion-label-md-h, .item-input .sc-ion-label-md-h {
  -ms-flex: initial;
  flex: initial;
  max-width: 500px !important;
  pointer-events: none;
}
